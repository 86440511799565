import React from 'react';
import { Row } from 'react-bootstrap';
import TypicalEngagementModal from './TypicalEngagementModal';

export default function CommercialStrategy(props) {
  const {
    data,
    data: { title, subtitle, cards },
  } = props;

  return (
    <div className={`${data.class} py-5`}>
      <div className="d-flex justify-content-center">
        <h3 className="text-white text-center">{title}</h3>
      </div>
      <span className="d-flex justify-content-center font-weight-bold text-white">{subtitle}</span>

      <Row className="justify-content-center pt-3">
        {cards.map(card => (
          <TypicalEngagementModal data={card} imgTitle="Commercial Strategy" />
        ))}
      </Row>
    </div>
  );
}
