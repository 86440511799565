import React, { Component } from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import Rounds from '../../../static/assets/typical_engagements/New_Opportunity_Assessment.svg';
import BPlogo from '../../../static/assets/typical_engagements/BPRG_Badge.png';

export default class TypicalEngagementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleShowModal = () => {
    document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = 0;
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = '64px';
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { showModal } = this.state;
    const {
      data: { title, modal },
      imgTitle,
    } = this.props;
    return (
      <Col xs={12} md={4} className="h-auto text-center my-3">
        <div
          onClick={this.handleShowModal}
          style={{ cursor: 'pointer' }}
          className="bg-white white-container p-4 animate-btn h-100"
        >
          <p>{title}</p>
        </div>
        {modal !== null ? (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="probono-modal"
            centered
            show={showModal}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton className="border-0 pb-0" />
            <Modal.Body className=" pb-5">
              <Container>
                <div>
                  <Row>
                    <Col lg={{ span: 3 }} className="">
                      <div className="text-center">
                        <img src={BPlogo} className="w-100" alt="" />
                      </div>
                      <div className="border-appcolor text-center pt-3">
                        <h6 className="text-grey">Project Showcase</h6>
                        <h5 className="text-appcolor">{imgTitle} </h5>
                      </div>
                    </Col>
                    <Col lg={9}>
                      <div className="pl-3 my-3 my-lg-0">
                        <h4 className="text-appcolor mb-2">{modal.title}</h4>
                        {/* <h5>{modal.subtitle}</h5> */}
                        <p className="text-grey mb-0">{modal.subtitle}</p>
                      </div>
                    </Col>
                  </Row>

                  <div className="pt-lg-3">
                    <Rounds className="h-100 w-100" />
                  </div>

                  <Row>
                    <Col lg={4}>
                      <div className="bg-blue h-100">
                        <h4 className="text-center bg-white text-appcolor py-3 text-uppercase">
                          {modal.business.title}
                        </h4>
                        <div className="p-3 modal-container">
                          {modal.business.description && modal.business.description !== null ? (
                            <p className="">{modal.business.description}</p>
                          ) : null}

                          {modal.business.list && modal.business.list !== null ? (
                            <div className="circle-appcolor-bullets">
                              <ul>
                                {modal.business.list.map(list => (
                                  <li>{list}</li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="bg-yellow h-100">
                        <h4 className="text-center bg-white text-yellow py-3 text-uppercase">
                          {modal.approach.title}
                        </h4>
                        <div className="modal-container p-3">
                          {modal.approach.description !== null ? (
                            <p>{modal.approach.description}</p>
                          ) : null}
                          {modal.approach.list !== null ? (
                            <div className="circle-apporange-bullets">
                              <ul>
                                {modal.approach.list.map(list => (
                                  <li>{list}</li>
                                ))}
                                {modal.approach.sublist && modal.approach.sublist !== null ? (
                                  <ul>
                                    {modal.approach.sublist.map(list => (
                                      <li>{list}</li>
                                    ))}
                                  </ul>
                                ) : null}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="bg-green h-100">
                        <h4 className="text-center bg-white text-green py-3 text-uppercase">
                          {modal.impact.title}
                        </h4>
                        <div className="modal-container p-3">
                          {modal.impact.description !== null ? (
                            <p>{modal.impact.description}</p>
                          ) : null}

                          {modal.impact.list && modal.impact.list !== null ? (
                            <div className="circle-appgreen-bullets">
                              <ul>
                                {modal.impact.list.map(list => (
                                  <li>{list}</li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Modal.Body>
          </Modal>
        ) : null}
      </Col>
    );
  }
}
