import { Power1 } from 'gsap';

export function animateStrategyCards(tl, element) {
  return tl.staggerFrom(
    element,
    0.5,
    {
      opacity: 0,
      y: 40,
      ease: Power1.easeOut,
    },
    0,
    0.5
  );
}

export function animateButton(tl, el) {
  return tl.staggerFrom(
    el,
    0.5,
    {
      opacity: 0,
      y: 20,
      ease: Power1.easeOut,
    },
    0,
    0.5
  );
}

export function animateDevMenu(tl, el) {
  const o = 1;
  return tl.staggerFrom(el, 0.5, { scale: 0.5, autoAlpha: 0 }, 0, o);
}
