import React, { PureComponent } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { graphql } from 'gatsby';
import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_typical-engagements.scss';
import Triagle from '../../static/img/healthcare_consulting/Healthcare_Triagle.svg';
import * as globalAnimation from '../animations/globalAnimation';
import { animateButton } from '../animations/HealthcareConsulting';
import CommercialStrategy from '../components/TypicalEngagements/CommercialStrategy';
import TypicalEngagementModal from '../components/TypicalEngagements/TypicalEngagementModal';

export default class TypicalEngagements extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.AnimateEaseInOut(this.tl, '.triangle-animation');
  }

  animateTabs = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateButton(tl, '.animate-btn');
    }
  };

  animateCommercialStrategy = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateButton(tl, '.animate-btn');
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: { title, tabOne, tabTwo, links },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Typical Engagements" />
        <Container fluid className="px-lg-0">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="main_wrapper">
                <div className="wrapper px-0">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                    <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={5} className="mb-md-5">
              <div style={{ width: 'auto' }} className="mx-4">
                {/* <div style={{ height: 'calc(100vh - 192px)', width: 'auto' }} className="mx-4"> */}
                <img src={Triagle} alt="" className="w-100 h-100 triangle-animation ml-lg-5" />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <svg className="hidden">
                  <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z" />
                  </defs>
                </svg>
                <Observer onChange={this.animateTabs} triggerOnce>
                  <Row className="tabs-style-shape no-gutters tabs_design">
                    <Col sm={12} className="nav_dtab">
                      <Nav justify variant="pills" className="ul_dtab">
                        {links.map(link => (
                          <Nav.Item className="li_dtab">
                            <Nav.Link eventKey={link.eventKey} className="a_tab pb-0">
                              <div className={link.class}>
                                <div className="d-none d-md-block">
                                  <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                    <use
                                      xmlns="http://www.w3.org/1999/xlink"
                                      xlinkHref="#tabshape"
                                    />
                                  </svg>
                                  <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                    <use
                                      xmlns="http://www.w3.org/1999/xlink"
                                      xlinkHref="#tabshape"
                                    />
                                  </svg>
                                </div>
                                <span className="font-weight-bold">{link.title}</span>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first" transition={false} className="bg-tabcolor-light">
                          <div className="health_tab py-5">
                            <Container>
                              <div className="py-5">
                                <div className="d-flex justify-content-center">
                                  <h3 className="text-white text-center">
                                    Corporate Development & Growth Strategies
                                  </h3>
                                </div>
                                <Row className="justify-content-center pt-3">
                                  {tabOne.section.map(modal => (
                                    <TypicalEngagementModal
                                      data={modal}
                                      imgTitle=" Enterprise Strategy"
                                    />
                                  ))}
                                </Row>
                              </div>
                            </Container>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" transition={false} className="bg-tabcolor-dark">
                          <div className="health_tab py-5">
                            <Container>
                              <Observer onChange={this.animateCommercialStrategy} triggerOnce>
                                {tabTwo.sections.map(section => (
                                  <CommercialStrategy data={section} />
                                ))}
                              </Observer>
                            </Container>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Observer>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "typical-engagements" } }) {
      frontmatter {
        title
        links {
          eventKey
          class
          title
        }
        tabOne {
          title
          eventKey
          class
          section {
            title
            modal {
              title
              subtitle
              business {
                title
                description
              }
              approach {
                title
                description
                list
                sublist
              }
              impact {
                title
                description
              }
            }
          }
        }
        tabTwo {
          title
          eventKey
          class
          sections {
            title
            subtitle
            class
            cards {
              title
              modal {
                title
                subtitle
                business {
                  title
                  description
                  list
                }
                approach {
                  title
                  description
                  list
                  sublist
                }
                impact {
                  title
                  description
                  list
                }
              }
            }
          }
        }
      }
    }
  }
`;
